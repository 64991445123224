import OneSignal from 'react-onesignal';
import React, { useCallback, useState, useEffect } from 'react';
import SignUpBg from '../../../assets/image/signUpBg.png';
import { toast } from 'react-toastify';
import { setLevelDataState, UPDATE_BALANCE, userData, userToken } from '../../../redux/slice/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import { ApiHandler } from '../../../helper/ApiHandler';
import Loading from '../../../components/Common/Loading';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Captcha from '../../../components/home/Captcha';


const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);
  const [loading, setLoading] = useState(false); // Default loading state is false
  const [couponCode, setCouponCode] = useState(''); 
  const [showCouponPopup, setShowCouponPopup] = useState(false); // State to control the coupon popup visibility
  {/* const [recaptchaToken, setRecaptchaToken] = useState(null);

 const handleCaptchaChange = useCallback((token) => {
    setRecaptchaToken(token);
  }, []);*/}

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    phone: '',
    referralCode: '',
    password: '',
    confirmPassword: '',
    age: '21', // Default age set to 21

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId; // This is the unique fingerprint
  };

  useEffect(() => {
    const generateCouponCode = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let code = '';
      for (let i = 0; i < 10; i++) {
        code += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return code;
    };

    const code = generateCouponCode();
    setCouponCode(code);
  }, []);


  const validateReferralCode = async (referralCode) => {
    try {
      const response = await ApiHandler('/validate_referral_code.php', 'POST', { referral_code: referralCode });
      
      if (response.data.status.code === 1) {
        return true; // Referral code is valid
      } else {
        toast.error(response.data.status.message || 'Invalid referral code');
        return false; // Referral code is invalid
      }
    } catch (error) {
      console.error('Error validating referral code:', error);
      toast.error('An error occurred while validating the referral code. Please try again.');
      return false; // Handle API errors gracefully
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  // Check if the phone number is provided
  if (!formData.phone) {
    toast.error('Phone number is required.');
    return;
  }

    {/* // reCAPTCHA validation
     if (!recaptchaToken) {
      toast.error('Please complete the reCAPTCHA.');
      return;
    }*/}

// Validate age
const userAge = parseInt(formData.age, 10);
if (!formData.age || isNaN(userAge) || userAge < 21) {
  toast.error('You must be at least 21 years old to register.');
  return;
}
  if (formData.referralCode) {
    const isReferralCodeValid = await validateReferralCode(formData.referralCode);
    if (!isReferralCodeValid) {
      return; // Stop submission if the referral code is invalid
    }
  }
    const fingerprint = await getFingerprint();


    

    // Password confirmation validation
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match!');
      return;
    }
// Age validation
if (parseInt(formData.age) < 18) {
  toast.error('You must be at least 21 years old to register.');
  return;
}
    // Construct the payload
    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      username: formData.username,
      phone: formData.phone,
      referral_code: formData.referralCode, // Include the referral code in payload
      password: formData.password,
      confirm_password: formData.confirmPassword,
      fingerprint: fingerprint, 

       // Include fingerprint
        // Include user agent
    };

    try {
      setLoading(true);
      const response = await ApiHandler('/signup.php', 'POST', payload, undefined, dispatch, navigate);

      if (response.data.status.code === 1) {
        toast.success(response.data.status.message);
        const { token } = response.data.data;

        dispatch(userToken({ token }));

         // Set tags using OneSignal with role included
      OneSignal.User.addTags({
        username: formData.username,
      });

        // Fetch bounus level data
        try {
          const response = await ApiHandler('/bounus_level.php', 'POST', undefined, token, dispatch, navigate);
          if (response.data && response.data.status.code === 1) {
            const enrichedData = response.data.data; // Assume enrichData is a function to process data
            dispatch(setLevelDataState(enrichedData));
          } else {
            console.error('Failed to fetch bonus levels: Invalid response');
          }
        } catch (error) {
          console.error('Failed to fetch bonus levels:', error);
        }

        // Fetch user profile data
        const profileResponse = await ApiHandler('/get_profile.php', 'GET', undefined, token, dispatch, navigate);

        if (profileResponse.data.status.code === 1) {
          dispatch(userData({ user: profileResponse.data.data }));
            // After fetching user data, store the coupon code in the database
            try {
              const couponPayload = {
                user_id: profileResponse.data.data.id,
                coupons_code: couponCode,
                coupon_balance: 2,
                expiry_date: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().slice(0, 19).replace('T', ' ')
              };
            
              const couponResponse = await ApiHandler('/bonus_coupons_add.php', 'POST', couponPayload, token);
            
              // Log the full response for debugging
              console.log('Coupon Response:', couponResponse);
            
              // Check if the coupon was added successfully
              if (couponResponse.data && couponResponse.data.status.code === 1) {
                console.log('Setting coupon data in localStorage');
            
                // Save the popup state and coupon code to localStorage
                localStorage.setItem('showCouponPopup', 'true');
                localStorage.setItem('couponCode', couponCode);
            
                // Navigate to the deposit page
                navigate('/deposit');
              } else {
                console.error('Failed to add coupon code:', couponResponse.data.status.message);
              }
            } catch (error) {
              console.error('Error adding coupon code:', error);
            }
            // Adjust delay as needed
          } else {
            toast.error('Failed to fetch profile data');
          }

        // Get User Balacnce
        try {
          const response = await ApiHandler('/user_balance.php', 'GET', undefined, token, dispatch, navigate);

          const { main_balance, bonus_balance, total_deposit, promotion_count, system_count, total_count } = response.data.data;

          // Dispatch the action to update the balance
          dispatch(UPDATE_BALANCE({ main_balance, bonus_balance, total_deposit, promotion_count, system_count, total_count }));
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      } else {
        toast.error(response.data.status.message);
        setFormData({
          ...formData,
          email: '',
          phone: '',
          password: '',
          confirmPassword: '',
        });
        setLoading(false);
      }
    } catch (error) {
      console.error('Signup error:', error);
      toast.error('An error occurred during signup. Please try again.');

      // Clear specific fields in case of an error
      setFormData({
        ...formData,
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
      });
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${SignUpBg})`,
      }}
      className='min-h-screen bg-cover'
    >
      <div className='container mx-auto px-4 md:px-8'>
        <div className='pt-16 md:pt-32'>
          <h1 className='flex font-bold justify-center items-center text-[#FFDD15] text-2xl md:text-4xl underline bg-cover'>
            REGISTER
          </h1>
          <div className='flex justify-center items-center flex-col text-white text-md md:text-xl font-bold uppercase mt-6 md:mt-10'>
          <p>Get $10 FREE bonus on your first order! </p> {/* Changed this line */}
          <p className='text-center'>Plus, get $2 Freeplay just for signing up - no deposit needed!</p> {/* Added this line */}
        </div>
        </div>

        <div className='flex justify-center mt-8 md:mt-10 pb-10'>
          <form onSubmit={handleSubmit} className='bg-[#0E0E0E] p-4 md:p-6 rounded-xl w-full md:w-2/3 lg:w-1/2'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
              <div>
                <label htmlFor='firstName' className='block text-white text-sm mb-2'>
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type='text'
                  id='firstName'
                  name='firstName'
                  value={formData.firstName}
                  onChange={handleChange}
                  className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                  required
                />
              </div>
              <div>
                <label htmlFor='lastName' className='block text-white text-sm mb-2'>
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type='text'
                  id='lastName'
                  name='lastName'
                  value={formData.lastName}
                  onChange={handleChange}
                  className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                  required
                />
              </div>
            </div>
            <div className='mb-4'>
              <label htmlFor='email' className='block text-white text-sm mb-2'>
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                required
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='username' className='block text-white text-sm mb-2'>
                Username <span className="text-red-500">*</span>
              </label>
              <input
                type='text'
                id='username'
                name='username'
                value={formData.username}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                required
              />
            </div>
            <div className="mb-4">
            <label htmlFor="phone" className="block text-white text-sm mb-2">
              Phone <span className="text-red-500">*</span>
            </label>
            <PhoneInput
  country={'us'} // Default country
  value={formData.phone}
  onChange={(phone) => setFormData({ ...formData, phone })} // Update state without validation
  inputProps={{
    name: 'phone',
    required: true, // Mark as required
    className: 'w-full pl-11 p-2 rounded-md bg-[#222222] text-white border border-white/50',
  }}
  containerClass="w-full relative"
  dropdownClass="custom-dropdown"
  inputClass="w-full"
  buttonClass="border border-white/50"
/>;
            
          </div>
          <div className="mb-4">
  <label htmlFor="age" className="block text-white text-sm mb-2">
    Age <span className="text-red-500">Minimum 21 *</span>
  </label>
  <input
    type="number"
    id="age"
    name="age"
    value={formData.age}
    onChange={(e) => {
      const value = e.target.value;
      if (/^\d*$/.test(value) && value.length <= 2) { // Allow only numbers and restrict to 2 digits
        setFormData((prevState) => ({
          ...prevState,
          age: value,
        }));
      }
    }}
    onInput={(e) => {
      if (e.target.value.length > 2) {
        e.target.value = e.target.value.slice(0, 2); // Enforce 2 digits on input
      }
    }}
    className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
    required
    min="21"
    max="99" // Ensure the logical minimum and maximum are applied
  />
</div>
            <div className='mb-4'>
              <label htmlFor='referralCode' className='block text-white text-sm mb-2'>
                Referral Code
                <span
                  className="ml-2 relative cursor-pointer"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <FaInfoCircle className="inline text-gray-400 hover:text-gray-200 font-bold w-4 h-4" color='white' />
                  {/* Tooltip */}
                  {showTooltip && (
                    <div
                      className="absolute left-0 mt-1 w-72 bg-gray-700 text-gray-200 text-[0.889rem] rounded-lg shadow-lg p-3 z-10 font-bold"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      If you use your friend's referral code and deposit at least $10, both of you will be granted $10 worth of free credits
                    </div>
                  )}
                </span>
                 (Optional)
              </label>
              <input
                type='text'
                id='referralCode'
                name='referralCode'
                value={formData.referralCode}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='password' className='block text-white text-sm mb-2'>
                Password <span className="text-red-500">*</span>
              </label>
              <input
                type='password'
                id='password'
                name='password'
                value={formData.password}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                required
              />
            </div>
            <div className='mb-6'>
              <label htmlFor='confirmPassword' className='block text-white text-sm mb-2'>
                Confirm Password <span className="text-red-500">*</span>
              </label>
              <input
                type='password'
                id='confirmPassword'
                name='confirmPassword'
                value={formData.confirmPassword}
                onChange={handleChange}
                className='w-full p-2 rounded-md bg-[#222222] text-white border border-white/50'
                required
              />
            </div>

<div className="mt-4">
  <p className="text-xs text-gray-400">
    By clicking "NEXT", you agree to our 
    <Link to="/terms-of-service" className="text-blue-500 hover:underline ml-1">Terms and Conditions</Link> and 
    <Link to="/privacy-policy" className="text-blue-500 hover:underline ml-1">Privacy Policy</Link>, including our use of cookies and how we handle your data in accordance with GDPR.
  </p>
</div>
<br />
<div className="mb-6">
           {/*<Captcha onCaptchaChange={handleCaptchaChange} /> */}
          </div>
          
            <button type='submit' className='w-full bg-yellow-500 text-black py-2 rounded-md font-semibold'>
              NEXT
            </button>
            
          </form>
          
        </div>
      </div>

    </div>
  );
};

export default Signup;
