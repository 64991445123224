import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PaymentButton from "../PaymentButtontwo";
import BitCoinLogo from "../../assets/image/bitcoin.png";
import WalletLogo from "../../assets/image/wallet.png";
import DogeCoinLogo from "../../assets/image/dogecoin.png";
import DollarIcon from "../../assets/image/dollarIcon.png";
import LitecoinLogo from "../../assets/image/Litecoin.png";
import cashAppLogo from "../../assets/image/cashApp.png";
import paypalLogo from "../../assets/image/paypal.png"; // Import PayPal logo

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLevelDataState } from "../../redux/slice/authSlice";
import Loading from "../Common/Loading";
import { ApiHandler } from "../../helper/ApiHandler";
import PaypalCheckoutButton from '../../components/PaypalCheckoutButton'; // Import your button component

const enrichCartDataWithPlatformId = (cartData, hotGames) => {
  return cartData.map((game) => {
    const matchedGame = hotGames.find(hotGame => hotGame.id === game.id);
    if (matchedGame) {
      // Append `platforms_id` from `hotGames` to `cartData` if match is found
      return {
        ...game,
        platforms_id: matchedGame.platforms_id
      };
    }
    return game;
  });
};


const CheckOutMain = ({ cartData, userData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [promoCode, setPromoCode] = useState("");

  const [discount, setDiscount] = useState(0);
  const [promoCodeData, setPromoCodeData] = useState({
    promoCodeAmount: "",
    promoCodePercentage: "",
  });

  const [bonuses, setBonuses] = useState({
    weeklyChallengePercentage: 0,
    depositBonuses: {},
    registrationBonusPercentage: 0,
  });

  const { auth } = useSelector((state) => state);

  const mainBalance = auth?.main_balance;
  const bonusBalance = auth?.bonus_balance;
  const bonusLevel = auth?.activeLevel;
  const token = auth?.token;
  const games = useSelector((state) => state?.auth?.hotGames);

  const enrichedCartData = enrichCartDataWithPlatformId(cartData, games);
  

  const FindGetActiveLevel = async () => {
    // Fetch bounus level data
    try {
      const response = await ApiHandler('/bounus_level.php', 'POST', undefined, token, dispatch, navigate);
      if (response.data && response.data.status.code === 1) {
        const enrichedData = response.data.data; // Assume enrichData is a function to process data
        dispatch(setLevelDataState(enrichedData));
      } else {
        console.error('Failed to fetch bonus levels: Invalid response');
      }
    } catch (error) {
      console.error('Failed to fetch bonus levels:', error);
    }
  }

  useEffect(() => {
    if (!bonusLevel) {
      if (token) {
        FindGetActiveLevel();
      }
    }
  }, [bonusLevel, token])

  const fetchBonuses = async () => {
    try {
      setLoading(true);
      const response = await ApiHandler('/check_game_validation.php', 'POST', {
        game_data: cartData.map((game) => ({
          game_id: game.id,
        }))
      }, token, dispatch, navigate);

      // Prepare a depositBonuses object to accumulate bonus values by game_id
      const depositBonuses = {};

      // Iterate over the coupons array in the API response
      response?.data?.data?.coupons?.forEach((coupon) => {
        const gameId = coupon.game_id;
        const couponBalance = parseFloat(coupon.coupon_balance);

        // Check if the game_id already exists in depositBonuses, if so, accumulate the balance
        if (depositBonuses[gameId]) {
          depositBonuses[gameId] += couponBalance;
        } else {
          // Otherwise, initialize it with the current coupon balance
          depositBonuses[gameId] = couponBalance;
        }
      });

      cartData.forEach((game) => {
        if (depositBonuses[game.id] !== undefined) {
          game.bonus = depositBonuses[game.id];
        }
      });

      const weeklyChallengePercentage =
        response?.data?.data?.weekly?.bonus_percentage / 100 || 0;

      setBonuses({
        weeklyChallengePercentage,
        depositBonuses,
        registrationBonusPercentage: response?.data?.data?.first_deposit_bonus ? response?.data?.data?.first_deposit_bonus : 0,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bonuses:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchBonuses();
  }, [cartData]);

  const totalAmount = cartData.reduce((acc, game) => acc + game.game_price * game.quantity, 0);

  const weeklyChallengeBonusAmount = totalAmount * bonuses.weeklyChallengePercentage;

  const bonusPercentage = parseFloat(bonusLevel?.bonus) || 0;
  const BonusLevelAmount = (totalAmount * bonusPercentage) / 100;

  const totalDepositBonusAmount = Object.values(bonuses.depositBonuses).reduce((acc, bonus) => acc + bonus, 0);

  const adjustedTotalAmount = totalAmount + totalDepositBonusAmount;

  const totalAfterBonuses = adjustedTotalAmount + weeklyChallengeBonusAmount;

  const originalAmount = totalAmount + totalDepositBonusAmount + weeklyChallengeBonusAmount + BonusLevelAmount + bonuses.registrationBonusPercentage;

  const discountAmount = totalAmount * (discount || 0);
  const finalTotal = totalAmount - discountAmount;

  const handlePromoCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiHandler('/verify_promocode.php', 'POST', { promocode: promoCode, }, token, dispatch, navigate);

      if (response?.data?.status?.code === 1) {
        const promocodeData = response?.data?.data;
        const discountPercentage = parseFloat(promocodeData?.promocode_percentage) / 100;

        setPromoCodeData({
          promoCodeAmount: totalAmount * discountPercentage,
          promoCodePercentage: promocodeData?.promocode_percentage,
        })

        setDiscount(discountPercentage);
        setPromoCode(promocodeData?.promocode);

        toast.success(`Promo code applied: ${promocodeData?.promocode_percentage}% discount`);
      } else {
        toast.error(response?.data?.status.message);
        setPromoCodeData({
          promoCodeAmount: 0,
          promoCodePercentage: 0,
        });
        setDiscount(0);
        setPromoCode('');
      }
    } catch (error) {
      console.error("Error applying promo code:", error);
      toast.error("Failed to apply promo code");
    }
  };



  const handlePayment = (method) => {
    navigate("/user/deposits/amount", {
      state: {
        isCheckoutAllowed: true,
        cartDataState: cartData,
        totalAmountState: originalAmount,
        method: method,
        discountamount: totalAmount * parseFloat(discount),
        discount: discount,
        bonuses: bonuses,
        totalDepositBonusAmount: totalDepositBonusAmount,
        weeklyChallengeBonusAmount: weeklyChallengeBonusAmount,
        totalAfterBonuses: totalAfterBonuses,
        finalTotal: finalTotal,
        promoCode: promoCode,
        promoCodeData: promoCodeData,
        BonusLevelAmount: BonusLevelAmount,
        registrationBonusPercentage: bonuses.registrationBonusPercentage,
        totalAmount: totalAmount
      },
    });
  };
  const handleSuccess = async (orderId) => {
    

    // Prepare data for the API call
    const amount = finalTotal; // Amount to deposit
    const totalAmount = originalAmount; // Original amount
    const gameDetails = cartData.map((game) => ({
      game_name: game.game_name || '',
      game_id: game.id || '',
      price: game.game_price ? parseFloat(game.game_price) : '',
      quantity: game.quantity ? parseFloat(game.quantity) : '',
      total: parseFloat(game.quantity * (game.game_price || game.price)),
      deposit_bonus: game.bonus ? parseFloat(game.bonus) : '',
      platformsID: game.platforms_id || '',
    }));

    // Call the deposit_game.php API
    try {
      const response = await ApiHandler('/deposit_game.php', 'POST', {
        email: userData.email,
        phone: userData.phone,
        amount: amount,
        promocode: promoCode,
        promocodeDiscount: promoCodeData.promoCodePercentage ? parseFloat(promoCodeData.promoCodePercentage) : '',
        discountamount: promoCodeData.promoCodeAmount ? parseFloat(promoCodeData.promoCodeAmount) : '',
        totalamount: totalAmount,
        payment_type: "PayPal", // Set the payment type to PayPal
        paypal_order_id: orderId, // Save the PayPal order ID

        weekly_challenge_bonus: weeklyChallengeBonusAmount ? parseFloat(weeklyChallengeBonusAmount) : '',
        game_detail: gameDetails,
        account_level_bonus: BonusLevelAmount ? parseFloat(BonusLevelAmount) : '',
        totalDepositBonusAmount: totalDepositBonusAmount ? parseFloat(totalDepositBonusAmount) : '',
        registration_bonus_for_new_users: bonuses.registrationBonusPercentage ? parseFloat(bonuses.registrationBonusPercentage) : '',
      }, token, dispatch, navigate);

  
    } catch (error) {
      console.error("Error processing deposit:", error);
      toast.error("Failed to process deposit. Please try again.");
    }
  };

  const handleError = (error) => {
    toast.error("Payment failed. Please try again.");
  };

  

  const handleCashAppClick = async () => {
    try {
      const response = await ApiHandler('/get_cashApp_barcode_image.php', 'POST', undefined, token, dispatch, navigate);

      const barcodeImage = response.data?.data?.barcode_image;

      if (response.data.status.code === 1 && barcodeImage) {
        navigate("/cashapp", {
          state: {
            directPassPage: false,
            isCheckoutAllowed: true,
            cartDataState: cartData,
            totalAmountState: originalAmount,
            method: "CashApp",
            discountamount: totalAmount * parseFloat(discount),
            discount: discount,
            promocodeDiscount: discount * 100,
            bonuses: bonuses,
            totalDepositBonusAmount: totalDepositBonusAmount,
            weeklyChallengeBonusAmount: weeklyChallengeBonusAmount,
            totalAfterBonuses: totalAfterBonuses,
            finalTotal: finalTotal,
            promoCode: promoCode,
            promoCodeData: promoCodeData,
            BonusLevelAmount: BonusLevelAmount,
            userData: userData,
            proof_image: barcodeImage,
            registrationBonusPercentage: bonuses.registrationBonusPercentage,
            totalAmount: totalAmount
          },
        });
      }
    } catch (error) {
      console.error("Error fetching CashApp barcode:", error);
    }
  };

  const handleWalletClick = (method) => {
    if (mainBalance && mainBalance >= finalTotal) {
      navigate("/user/deposits/amount", {
        state: {
          isCheckoutAllowed: true,
          cartDataState: cartData,
          totalAmountState: originalAmount,
          method: method,
          discountamount: totalAmount * parseFloat(discount),
          discount: discount,
          bonuses: bonuses,
          totalDepositBonusAmount: totalDepositBonusAmount,
          weeklyChallengeBonusAmount: weeklyChallengeBonusAmount,
          totalAfterBonuses: totalAfterBonuses,
          finalTotal: finalTotal,
          promoCode: promoCode,
          promoCodeData: promoCodeData,
          BonusLevelAmount: BonusLevelAmount,
          registrationBonusPercentage: bonuses.registrationBonusPercentage,
          totalAmount: totalAmount
        },
      });
    } else {
      toast.error("Insufficient balance in wallet");
    }
  };

  const handleBonusClick = () => {
    if (bonusBalance && bonusBalance >= finalTotal) {
      navigate("/user/deposits/amount", {
        state: {
          isCheckoutAllowed: true,
          cartDataState: cartData,
          totalAmountState: originalAmount,
          method: "Bonus",
          discountamount: totalAmount * parseFloat(discount),
          discount: discount,
          bonuses: bonuses,
          totalDepositBonusAmount: totalDepositBonusAmount,
          weeklyChallengeBonusAmount: weeklyChallengeBonusAmount,
          totalAfterBonuses: totalAfterBonuses,
          finalTotal: finalTotal,
          promoCode: promoCode,
          promoCodeData: promoCodeData,
          BonusLevelAmount: BonusLevelAmount,
          registrationBonusPercentage: bonuses.registrationBonusPercentage,
          totalAmount: totalAmount
        },
      });
    } else {
      toast.error("Insufficient balance in bonus");
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-wrap justify-between p-4 md:p-8 items-start">
      {/* Order Summary */}
      <div
        className="w-full lgs:w-[50%] bg-[#1f2937] p-3 md:p-6 rounded-lg"
        style={{ borderColor: "rgba(255, 255, 255, 0.16)", borderWidth: "1px" }}
      >
        <h2 className="text-white text-2xl md:text-4xl font-bold mb-4">Checkout</h2>
        <table className="w-full text-white">
          <thead>
            <tr className="text-sm md:text-lg font-semibold">
              <th className="text-left p-2">NAME</th>
              <th className="text-left p-2">PRICE</th>
              <th className="text-left p-2">QUANTITY</th>
              <th className="p-2 text-right">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {cartData.map((game, index) => {
              const gameTotal = game.game_price * game.quantity;
              const depositBonusAmount = bonuses.depositBonuses[game.id] || 0
              return (
                <tr
                  key={index}
                  className="border-b border-gray-700 text-sm md:text-base font-semibold md:font-medium"
                >
                  <td className="p-2">{game.game_name}</td>
                  <td className="p-2">${parseFloat(game.game_price).toFixed(2)}</td>
                  <td className="p-2">{game.quantity}</td>
                  <td className="p-2 text-right">
                    ${gameTotal.toFixed(2)} <br />
                    {depositBonusAmount ? (
                      <p className="text-yellow-400">Deposit Bonus ${depositBonusAmount.toFixed(2)}</p>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="text-right text-white text-sm md:text-base mt-4 font-bold">
          Total: ${totalAmount.toFixed(2)}
        </div>
        {totalDepositBonusAmount > 0 && (
          <div className="text-right text-yellow-400 font-bold">
            Total Deposit Bonuses: ${totalDepositBonusAmount?.toFixed(2)}
          </div>
        )}
        <div className="text-right text-white text-sm md:text-base mt-4 font-bold">
          Adjusted Total: ${adjustedTotalAmount.toFixed(2)}
        </div>
        {weeklyChallengeBonusAmount > 0 && (
          <div className="text-right text-yellow-400 font-bold">
            Weekly Challenge Bonus: ${weeklyChallengeBonusAmount.toFixed(2)}
          </div>
        )}
        {bonuses?.registrationBonusPercentage > 0 && (
          <div className="text-right text-yellow-400 font-bold">
            Registration Bonus: ${bonuses?.registrationBonusPercentage.toFixed(2)}
          </div>
        )}
        {BonusLevelAmount > 0 && (
          <div className="text-right text-yellow-400 font-bold">
            Bonus Level discount: ${BonusLevelAmount.toFixed(2)}
          </div>
        )}
        {discount > 0 && (
          <div className="text-right text-green-400 font-bold">
            Discount: ${(discountAmount).toFixed(2)} ({(discount * 100)}% Promo Code Applied)
          </div>
        )}
        <div className="text-right text-white font-bold mt-4">
          Final Total: ${finalTotal.toFixed(2)}
        </div>
      </div>

      {/* Order Details */}
      <div
        className="w-full lgs:w-[48%] bg-[#1f2937] py-6 px-4 rounded-lg mt-4 lgs:mt-0"
        style={{ borderColor: "rgba(255, 255, 255, 0.16)", borderWidth: "1px" }}
      >
        <h3 className="text-white text-2xl sm:text-3xl font-semibold mb-4">Order Details</h3>
        <div className="text-white mb-4">
          <div className="css-17ejtfq text-sm md:text-base font-medium">
            <p>Order Total</p>
            <p className="text-right break-words">${finalTotal?.toFixed(2)}</p>
            <p>Name</p>
            <p className="text-right break-words">{userData?.first_name}</p>
            <p>Email</p>
            <p className="text-right break-words">{userData?.email}</p>
            <p>Phone</p>
            <p className="text-right break-words">{userData?.phone}</p>
          </div>
        </div>
        <div>
          <form onSubmit={handlePromoCodeSubmit}>
            <p className="text-white font-semibold text-base mb-4">Coupon Code</p>
            <div className="flex items-center gap-3">
              <input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                placeholder="Coupon Code"
                className="w-full px-2 py-3 rounded bg-[#0e1629] text-white flex-[2]"
              />
              <button type="submit" className="w-full px-4 py-2 rounded bg-[#290a47] border border-white/15 text-white font-bold shadow-lg flex-1">
                APPLY
              </button>
            </div>
          </form>
        </div>
        <div className="text-white text-base font-semibold mb-2 mt-5">PLACE AN ORDER WITH</div>
        {/* Litecoin Recommendation Note */}
        <div className="text-right text-gray-400 text-xs mt-2">
          * For lower fees on crypto deposits, we recommend using Litecoin (LTC).
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
          <PaymentButton
            bgColor={"#F7931A"}
            logo={BitCoinLogo}
            title={"Bitcoin"}
            onClick={() => handlePayment("BTC")}
          />
          <PaymentButton
            bgColor={"#345D9D"}
            logo={LitecoinLogo}
            title={"Litecoin"}
            onClick={() => handlePayment("LTC")}
          />
          <PaymentButton
            bgColor={"#C2A633"}
            logo={DogeCoinLogo}
            title={"Dogecoin"}
            onClick={() => handlePayment("DOGE")}
          />
          <PaymentButton
            bgColor={"#8CC43E"}
            logo={WalletLogo}
            title={"Wallet"}
            onClick={() => handleWalletClick("Wallet")}
          />
         
          <PaymentButton bgColor={"#FF5F66"} logo={DollarIcon} title={"Bonus"} onClick={() => { handleBonusClick("Bonus") }} />
          <PaymentButton
            bgColor={"#FF9085"}
            logo={cashAppLogo}
            title={"Cashapp"}
            onClick={handleCashAppClick}
          />
        </div>
        <hr className="py-2 mt-4"/>
         {/* Integrate PayPal Checkout Button */}
         <div className="mt-4 bg-white p-4 rounded shadow ">
          <PaypalCheckoutButton 
            product={{ description: "Total Payment for Coins", price: (finalTotal + 0.49) / (1 - 0.0299) }}             
            onSuccess={handleSuccess} 
            onError={handleError} 
          /></div>
      </div>
    </div>
  );
};

export default CheckOutMain;