import React, { useEffect, useState } from 'react';
import Hero from '../../../components/Hero';
import DepositBg from '../../../assets/image/blogBg.png';
import MainContent from '../../../components/deposit/MainContent';
import Loading from '../../../components/Common/Loading';
import { updateGame } from '../../../redux/slice/gamesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { ApiHandler } from '../../../helper/ApiHandler';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify'; 
import { ClipboardIcon } from '@heroicons/react/24/outline' // Import the icon

const Deposit = () => {
  const [counters, setCounters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [couponCode, setCouponCode] = useState('');

  const games = useSelector((state) => state.games.games);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchPlatforms = async () => {
    try {
      const response = await ApiHandler('/get_game_list.php', 'POST', { gameID: "" , filter: "platform"}, undefined, dispatch, navigate);
      if (response.data.status.code === "1") {
        const data = response.data.data;
        data.forEach((game) => {
          const updatedGame = {
            ...game,
            is_game_add: game.is_game_add ? game.is_game_add : false,
            quantity: 10,
            platforms_id: game.platforms_id ? game.platforms_id : 0
          };
          dispatch(updateGame(updatedGame));
        });
        setCounters(new Array(response.data.data.length).fill(10));
      }
    } catch (error) {
      console.error('Error fetching depositItems:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlatforms();

    const popupState = localStorage.getItem('showCouponPopup');
    const savedCouponCode = localStorage.getItem('couponCode');

    if (popupState === 'true' && savedCouponCode) {
      setShowCouponPopup(true);
      setCouponCode(savedCouponCode);
      localStorage.removeItem('showCouponPopup');
      localStorage.removeItem('couponCode');
    }
  }, []);

  const handleCopy = () => {
    toast.success('Coupon code copied to clipboard!'); 
  };

  const handleClosePopup = () => {
    setShowCouponPopup(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Hero bgImg={DepositBg} title={"DEPOSIT NOW"} />
      <MainContent depositItems={games} counters={counters} setCounters={setCounters} fetchPlatforms={fetchPlatforms} />

      {showCouponPopup && (
       <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80 p-4">
       <div className="relative bg-gradient-to-br from-gray-800 via-black to-gray-900 text-white rounded-lg w-full max-w-md p-6 shadow-2xl">
           <button
               onClick={handleClosePopup}
               className="absolute top-3 right-3 text-white text-2xl"
           >
               &times;
           </button>

           <h2 className="text-2xl font-bold mb-4 text-center">Congratulations!</h2>
           <p className="text-lg mb-4 text-center">You've earned a $2 Freeplay!</p>
           <p className="mb-4 text-center">Here's your unique coupon code:</p>

           <div className="border-2 border-dashed border-yellow-500 rounded-lg p-4 mb-6 flex justify-center items-center">
        <CopyToClipboard text={couponCode} onCopy={handleCopy}>
          <div className="flex items-center">
            <span className="text-2xl font-bold text-yellow-500 mr-2">
              {couponCode}
            </span>
            
          </div>
        </CopyToClipboard>
        <CopyToClipboard text={couponCode} onCopy={handleCopy}>
    <button className="bg-yellow-500 hover:bg-yellow-600 text-black font-medium py-1 px-3 rounded flex items-center"> {/* Use flexbox for alignment */}
      <ClipboardIcon className="w-6 h-6 mr-2" /> {/* Icon on the left */}
      <span className="text-sm">Copy Code</span> {/* Text on the right */}
    </button>
  </CopyToClipboard>
      </div>
      

           <p className="mb-4 text-center">
               This coupon is valid for <span className="font-bold">24 Hours</span>
           </p>
           <p className="text-xs text-center mb-4 text-gray-400">
               <span className="text-yellow-500 font-semibold">Important:</span> You'll get 50% of any winnings you make using your $2 freeplay bonus.
           </p>
           <Link to="/bonuses">
               <button className="bg-[#FFD700] hover:bg-[#DAA520] text-black font-bold py-2 px-4 rounded-md w-full transition-colors duration-200">
                   Redeem Now
               </button>
           </Link>
       </div>
   </div>
      )}
    </>
  );
};

export default Deposit;